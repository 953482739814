import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import _ from "lodash";
import { useEffect, useState } from "react";
import { A11y, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import EventCard from "../../components/EventCard/EventCard";
import GeneralConst from "../../consts/GeneralConst";
import EventService from "../../services/EventService";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./EventDashboardCard.scss";

const EventDashboardCard = () => {
  const [events, setEvents] = useState([]);
  const eventStrings = GeneralStrings.DASHBOARD.EVENTS;

  useEffect(() => {
    EventService.getAllEvents().then((response) => {
      const events = response?.data;
      setEvents(events);
    });
  }, []);

  return (
    <Paper elevation={0} className="event-dashboard-card">
      <Card>
        <CardContent className="content-container">
          <h3 className="card-header">{eventStrings.EVENTS}</h3>
          <div className="card-body-container">
            <Swiper
              modules={[Pagination, A11y]}
              spaceBetween={35}
              slidesPerView="auto"
              width={"auto"}
            >
              {!_.isEmpty(events) &&
                events.map((event) => (
                  <SwiperSlide key={"post-" + event.id}>
                    <EventCard
                      event={event}
                      size={GeneralConst.EVENT_CARD_SIZE.SMALL}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default EventDashboardCard;
